import React, {useEffect, useRef} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {IoMenu} from 'react-icons/io5';
import Modal from '../components-elements/Modal';
import './NavigationMain.css';

/** A single entry in the main navigation */
interface INavButtonProps {
  linkTarget: string,
  content: string,
  icon: React.ReactElement,
  isActive: boolean,
  isExternal?: boolean,
  onClick?: () => void
}
const NavButton = (props: INavButtonProps) => {
  if (!props.isExternal) {
    return (
      <Link to={props.linkTarget} className={`button icon-button nav-button${props.isActive ? ' active' : ''}`} onClick={props.onClick}>
        {props.icon}
        <span>{props.content}</span>
      </Link>
    );
  }
  return (
    <a href={props.linkTarget} target="_blank" className="button icon-button nav-button"
      rel="noreferrer noopener" onClick={props.onClick}>
      {props.icon}
      <span>{props.content}</span>
    </a>
  );
};

/** Root component */
export interface INavRoute {
  linkTarget: string,
  content: string,
  icon: React.ReactElement,
  external?: boolean
}
const NavigationMain = (props: {routes: INavRoute[]}) => {
  const location = useLocation();
  const [isOpen, setOpen] = React.useState(false);
  const navButton = useRef<HTMLSpanElement | null>(null);
  const navButtons = useRef<HTMLDivElement | null>(null);

  let scrollFlagCollapsed = false;

  const handleScroll = () => {
    if (window.scrollY > 320 && !scrollFlagCollapsed) {
      scrollFlagCollapsed = true;
      navButtons.current?.classList.add('collapse');
      navButton.current?.classList.add('collapse');
    }
    if (window.scrollY < 300 && scrollFlagCollapsed) {
      scrollFlagCollapsed = false;
      navButtons.current?.classList.remove('collapse');
      navButton.current?.classList.remove('collapse');
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <span id="menu-button" className="button in-bar nav-button" role="button"
        tabIndex={0} aria-label="Show Navigation" onClick={() => setOpen(true)}>
        <IoMenu />
      </span>
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <span id="menu-button-collapsed" ref={navButton} className="button in-bar nav-button only-large"
        role="button" tabIndex={0} aria-label="Show Navigation"
        onClick={() => {
          navButtons.current?.classList.toggle('collapse');
          scrollFlagCollapsed = false;
        }}>
        <IoMenu />
      </span>
      <div id="main-nav-container">
        <div id="nav-buttons" ref={navButtons}>
          {props.routes.map(route => (<NavButton key={`navbtnmain-${route.content}`} linkTarget={route.linkTarget} content={route.content}
            icon={route.icon} isActive={location.pathname === route.linkTarget} isExternal={route.external} />))}
        </div>
      </div>

      <Modal id="nav-buttons-mobile" fillContent
        isOpen={isOpen} onClose={() => setOpen(false)}>
        <div id="nav-buttons-mobile-container">
          {props.routes.map(route => (<NavButton key={`navbtnmobile-${route.content}`} linkTarget={route.linkTarget} content={route.content}
            icon={route.icon} isActive={location.pathname === route.linkTarget} isExternal={route.external}
            onClick={() => setOpen(false)} />))}
        </div>
      </Modal>
    </>
  );
};

export default NavigationMain;
