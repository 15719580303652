import {IoReload} from 'react-icons/io5';
import './LoadingIcon.css';
import {CSSProperties} from 'react';

const LoadingIcon = (props: {hidden?: boolean, style?: CSSProperties}) => (
  <div className="loading-icon-wrapper" style={{...props.style, display: props.hidden ? 'none' : 'block'}}>
    <IoReload className="rotating" />
  </div>
);

export default LoadingIcon;
