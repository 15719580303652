import {useContext, useEffect, useState} from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoLockClosed, IoLogInOutline, IoLogOutOutline, IoPerson} from 'react-icons/io5';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import useStorage, {IStorageLogin} from '../helpers/useStorage';
import useLoginManager from '../helpers/useLoginManager';
import NavigationMain, {INavRoute} from './NavigationMain';
import Modal from '../components-elements/Modal';
import Input from '../components-elements/Input';
import './AccountHeader.css';
import Checkbox from '../components-elements/Checkbox';
import WarningBanner from '../components-elements/WarningBanner';
import Picture from '../components-elements/Picture';

const AccountHeader = (props: {routes: INavRoute[]}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const {globalState} = useContext(GlobalContext);
  const {t, i18n} = useTranslation();
  const {loginStorage} = useStorage();
  const {login, ELoginError, logout} = useLoginManager();

  const [accountPopupOpen, setAccountPopupOpen] = useState(false);
  const [loginOpen, setLoginOpen] = useState(false);
  const [loginUser, setLoginUser] = useState('');
  const [loginUserError, setLoginUserError] = useState(false);
  const [loginPass, setLoginPass] = useState('');
  const [loginPassError, setLoginPassError] = useState(false);
  const [loginRemember, setLoginRemember] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [resetPWOpen, setResetPWOpen] = useState(false);
  const [resetPWUserMail, setResetPWUserMail] = useState('');
  const [resetPWErrorMessage, setResetPWErrorMessage] = useState('');
  const [resetPWSuccess, setResetPWSuccess] = useState(false);

  const sendLogin = () => {
    login({user: loginUser, pass: loginPass}).then(res => {
      if (res !== ELoginError.NONE) {
        let usererror = false;
        let passerror = false;
        switch (res) {
          case ELoginError.GENERIC: setErrorMessage(t('error_generic')); break;
          case ELoginError.NOUSER:
            setErrorMessage(t('account_error_nouser'));
            usererror = true;
            break;
          case ELoginError.NOPASS:
            setErrorMessage(t('account_error_nopass'));
            passerror = true;
            break;
          case ELoginError.LOGININVALID:
            setErrorMessage(t('account_error_credentials'));
            usererror = true;
            passerror = true;
            break;
          case ELoginError.VERIFICATIONPENDING: setErrorMessage(t('account_error_registration_pending')); break;
          default: break;
        }
        setLoginUserError(usererror);
        setLoginPassError(passerror);
        return;
      }
      setLoginUserError(false);
      setLoginPassError(false);
      setErrorMessage('');

      if (loginRemember) loginStorage.setVal({user: loginUser, pass: loginPass});
      // save login as temporary cookie
      const now = new Date();
      now.setTime(now.getTime() + (3600 * 1000));
      document.cookie = `log=${btoa(JSON.stringify({user: loginUser, pass: loginPass}))}; expires=${now.toUTCString()}; SameSite=Strict`;

      setLoginOpen(false);
      if (location.pathname === '/') navigate('/account');
    });
  };

  const resetPW = () => {
    if (!resetPWUserMail) {
      setResetPWErrorMessage(t('account_error_nouser'));
      return;
    }

    ZULL_API.POST({
      endpoint: `account/${resetPWUserMail}/resetpw`,
      body: JSON.stringify({langCode: i18n.language.substring(0, 2)})
    }).then(res => {
      if (!res.ok) {
        let newErrorMessage = '';
        if (res.status === 500) newErrorMessage = t('error_generic');
        if (res.body?.includes('username or email')) newErrorMessage = t('account_error_resetpw_notfound');
        setResetPWErrorMessage(newErrorMessage);
        return;
      }
      setResetPWSuccess(true);
    });
  };

  useEffect(() => {
    let storedLogin: IStorageLogin | undefined;
    try {
      storedLogin = loginStorage.getVal();
    } catch {
      if (document.cookie && document.cookie.includes('log=')) {
        const match = document.cookie.match(/(^| )log=([^;]+)/);
        if (match) storedLogin = JSON.parse(atob(match[2])) as IStorageLogin;
      } else return;
    }
    if (storedLogin && storedLogin.user && storedLogin.pass) login(storedLogin);
  }, []);

  return (
    <div id="account-header-container">
      <div id="account-header-content">
        <div id="account-header-buttons">
          <button type="button" id="login-button" className="button in-bar icon-button large-icon"
            style={{display: globalState.isLoggedIn ? 'none' : undefined}} onClick={() => setLoginOpen(true)}>
            <IoLogInOutline />
            <span>{t('account_login')}</span>
          </button>
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button type="button" id="account-button-mobile" className="button in-bar icon-only only-mobile"
            style={{display: globalState.isLoggedIn ? undefined : 'none'}} onClick={() => setAccountPopupOpen(true)}>
            <IoPerson />
          </button>
          <button type="button" id="account-button-desktop" className="button in-bar icon-button only-large"
            style={{display: globalState.isLoggedIn ? undefined : 'none'}} onClick={() => setAccountPopupOpen(true)}>
            <IoPerson />
            <span>{t('account')}</span>
          </button>
          <NavigationMain routes={props.routes} />
        </div>

        <div id="account-header-menu-popup" className={accountPopupOpen ? 'show' : undefined}>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div id="account-header-menu-catcher" onClick={e => {
            e.stopPropagation();
            setAccountPopupOpen(false);
          }} />
          <Link to="/account" className="button icon-button" onClick={() => setAccountPopupOpen(false)}>
            <IoPerson />
            <span id="account-header-button-account">{t('account')}</span>
          </Link>
          <button type="button" className="button icon-button" onClick={() => {setAccountPopupOpen(false); logout();}}>
            <IoLogOutOutline />
            <span>{t('account_logout')}</span>
          </button>
        </div>

        <span id="account-header-currency" className={globalState.isLoggedIn ? 'show' : undefined}>
          <Picture path="dp" alt="" /> DP: <span className="currency-left">{globalState.dp}</span>
          <Picture path="vp" alt="" /> VP: <span>{globalState.vp}</span>
        </span>

        <Modal id="login-modal" className="modal" isOpen={loginOpen}
          onClose={() => setLoginOpen(false)} title={t('account_login_title')}>
          <WarningBanner type="error" show={!!errorMessage}>
            {errorMessage}
          </WarningBanner>

          <form onSubmit={e => {e.preventDefault(); sendLogin();}}>
            <Input label={t('account_username')} text={loginUser} id="login-username"
              onChange={e => setLoginUser(e.target.value.trim())} icon={<IoPerson />} isError={loginUserError}
              autocomplete="username" />
            <Input label={t('account_password')} text={loginPass} id="login-password"
              onChange={e => setLoginPass(e.target.value.trim())} type="password" icon={<IoLockClosed />}
              isError={loginPassError} autocomplete="current-password" />
            <Checkbox label={t('account_remember')} checked={loginRemember} onChange={e => setLoginRemember(e.target.checked)} />
            <div id="login-modal-links">
              <Link to="/register" id="account-register" onClick={() => setLoginOpen(false)}>
                {t('account_register')}
              </Link>
              <button id="account-resetpw" className="button-a" type="button"
                onClick={() => setResetPWOpen(true)}>
                {t('account_resetpw')}
              </button>
            </div>
            <button type="submit" className="button primary">
              {t('account_login')}
            </button>
          </form>
        </Modal>

        <Modal id="pw-reset-modal" className="modal" isOpen={resetPWOpen}
          onClose={() => setResetPWOpen(false)} title={t('account_resetpw')}>
          <WarningBanner type="error" show={!!resetPWErrorMessage}>
            {resetPWErrorMessage}
          </WarningBanner>
          {!resetPWSuccess ?
            <form onSubmit={e => {e.preventDefault(); resetPW();}}>
              <Input label={`${t('account_username')} / ${t('account_email')}`} text={resetPWUserMail} id="pw-reset-username"
                onChange={e => setResetPWUserMail(e.target.value.trim())} icon={<IoPerson />} autocomplete="username" />
              <button type="submit" className="button primary">
                {t('account_resetpw')}
              </button>
            </form>
            :
            <WarningBanner type="info">
              {t('account_resetpw_pending')}
            </WarningBanner>
          }
        </Modal>
      </div>
    </div >
  );
};

export default AccountHeader;
