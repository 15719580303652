import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import './Footer.css';
import Picture from '../components-elements/Picture';
import SocialLinks from '../components-elements/SocialLinks';

const Footer = () => {
  const {t} = useTranslation();

  return (
    <div id="footer">
      <SocialLinks />
      <Picture path="line" alt="" />
      <div>
        <Link to="/">{t('nav_home')}</Link>
        <Link to="/imprint">{t('nav_imprint')}</Link>
        <Link to="/tos">{t('nav_tos')}</Link>
        <Link to="/privacy">{t('nav_privacy')}</Link>
        <Link to="/refundpolicy">{t('nav_refundpolicy')}</Link>
        <Link to="/rules">{t('nav_rules')}</Link>
      </div>
      <Picture path="line" alt="" />
      <p id="footer-disclaimer">{t('footer_disclaimer')}</p>
      <br />
      <p id="footer-allrightsreserved">{t('footer_allrightsreserved')} © WOWZULL.</p>
      <br />
      <div id="easteregg-container">
        <Picture path="fox_easteregg" alt="" lazy
          title="Proudly presented by Traxx."
          id="easteregg" />
      </div>
    </div>
  );
};

export default Footer;
