import {useContext} from 'react';
import {GlobalContext} from '../helpers/globalContext';
import './VideoBackground.css';

const VideoBackground = () => {
  const {globalState} = useContext(GlobalContext);

  return (
    <div className="video-background-container">
      <video className="video-background" playsInline autoPlay
        muted loop poster={`${globalState.baseURL}assets/arthas.jpg`}>
        <source src={`${globalState.baseURL}assets/arthas.webm`} type="video/webm" />
        <source src={`${globalState.baseURL}assets/arthas.mp4`} type="video/mp4" />
      </video>
      <img loading="lazy" src={`${globalState.baseURL}assets/arthas.jpg`} className="video-background"
        alt="" style={{position: 'absolute', top: 0, left: 0, zIndex: -1}} />
      <div className="video-background-overlay" />
    </div>
  );
};

export default VideoBackground;
