import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoDesktopOutline, IoLogoDiscord, IoPerson, IoDiamond} from 'react-icons/io5';
import BasePageLayout from './BasePageLayout';
import './PageHelp.css';

const discordLink = 'https://discord.gg/EKs7jzdXGQ';

const PageHelp = () => {
  const {t} = useTranslation();
  return (
    <BasePageLayout title={t('nav_help')}>
      <div id="help-index-content">
        <div id="refer-discord">
          <div id="discord-logo-large">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <a href={discordLink} target="_blank" rel="noreferrer noopener"><IoLogoDiscord /></a>
          </div>
          <div id="refer-discord-text">
            <span>{t('help_use_discord_1')}</span>
            <span>{t('help_use_discord_2')} <a href={discordLink} target="_blank" rel="noreferrer noopener">Discord</a></span>
          </div>
        </div>


        <div id="help-index">
          <span>{t('help_use_faq')}</span>
          <div id="help-links">
            <Link to="/help/technical" className="help-link">
              <IoDesktopOutline />
              <span>{t('help_technical')}</span>
            </Link>
            <Link to="/help/account" className="help-link">
              <IoPerson />
              <span>{t('help_account')}</span>
            </Link>
            <Link to="/help/game" className="help-link">
              <IoDiamond />
              <span>{t('help_game')}</span>
            </Link>
          </div>
        </div>
      </div>
    </BasePageLayout>
  );
};

export default PageHelp;
