export interface IStorageLogin {
  user: string,
  pass: string
}

const useStorage = () => {
  const loginStorage = {
    getVal: () => {
      const ret = JSON.parse(localStorage.getItem('login') || '') as IStorageLogin;
      ret.pass = atob(ret.pass);
      return ret;
    },
    setVal: (arg: IStorageLogin) => localStorage.setItem('login', JSON.stringify({user: arg.user, pass: btoa(arg.pass)})),
    clear: () => localStorage.removeItem('login')
  };

  const cookieConsentStorage = {
    getVal: () => localStorage.getItem('cookie_consent') === 'true',
    setVal: (arg: boolean) => localStorage.setItem('cookie_consent', arg ? 'true' : 'false'),
    clear: () => localStorage.removeItem('cookie_consent')
  };

  return {loginStorage, cookieConsentStorage};
};

export default useStorage;
