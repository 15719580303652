/* eslint-disable jsx-a11y/control-has-associated-label */
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IconContext} from 'react-icons';
import {IoCaretDown, IoCaretUp, IoLogoPaypal} from 'react-icons/io5';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import Picture from '../components-elements/Picture';
import LoadingIcon from '../components-elements/LoadingIcon';
import WarningBanner from '../components-elements/WarningBanner';
import BasePageLayout from './BasePageLayout';
import './PageDonate.css';

interface IDonateOption {
  dp: number,
  eur: number
}

const PageDonate = () => {
  const {t} = useTranslation();
  const {globalState} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [donateOptions, setDonateOptions] = useState<IDonateOption[]>([]);
  const [currentOption, setCurrentOption] = useState(0);
  const [isError, setIsError] = useState(false);

  const getDonateOptions = () => {
    ZULL_API.GET({endpoint: 'public/donateoptions'}).then(res => {
      if (res.ok && res.body) {
        setDonateOptions(JSON.parse(res.body) as IDonateOption[]);
        setCurrentOption(0);
        setIsLoading(false);
      } else console.error(res);
    });
  };

  const sendDonate = () => {
    if (!globalState.isLoggedIn || globalState.isLoginLoading || !globalState.username || !globalState.password) return;
    setIsLoading(true);
    const option = donateOptions[currentOption];
    ZULL_API.POST({
      endpoint: `account/${globalState.userid}/donate`,
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({amountdp: option.dp})
    }).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        setIsError(true);
        setIsLoading(false);
        return;
      }
      const url = JSON.parse(res.body) as {url: string};
      setIsError(false);
      setIsLoading(false);
      window.location.href = url.url;
    });
  };

  useEffect(() => getDonateOptions(), []);
  const iconAttr = () => ({attr: {'preserveAspectRatio': 'none'}});

  if (!globalState.isLoggedIn || !globalState.username || !globalState.password) return (
    <BasePageLayout title={t('nav_donate')}>
      <WarningBanner type="error">
        {t('account_error_needlogin')}
      </WarningBanner>
    </BasePageLayout>
  );
  if (isLoading || globalState.isLoginLoading || donateOptions.length === 0) return (
    <BasePageLayout title={t('nav_donate')}>
      <LoadingIcon />
    </BasePageLayout>
  );

  return (
    <BasePageLayout title={t('nav_donate')}>
      <div>
        <WarningBanner type="error" show={isError}>
          {t('error_generic')}
        </WarningBanner>

        <div id="donation-wrapper">
          <div id="donation-paypal-section">
            <span>{t('donate_processing')}</span>
            <Picture path="paypal" alt="PayPal" />
          </div>

          <div id="donation-dp-section">
            <IconContext.Provider value={iconAttr()}>
              <button id="dp-up" type="button"
                className={currentOption === donateOptions.length - 1 ? 'disabled' : undefined}
                onClick={() => {if (currentOption !== donateOptions.length - 1) setCurrentOption(currentOption + 1);}}>
                <IoCaretUp />
              </button>
              <Picture path="coins" alt="DP Coins" />
              <span>{donateOptions[currentOption].dp}</span>
              <button id="dp-down" type="button"
                className={currentOption === 0 ? 'disabled' : undefined}
                onClick={() => {if (currentOption !== 0) setCurrentOption(currentOption - 1);}}>
                <IoCaretDown />
              </button>
            </IconContext.Provider>
          </div>

          <div id="donation-details-section">
            <div id="donation-price">
              {donateOptions[currentOption].eur} €
            </div>
            <div className="flex-pad" />
            <span>{t('donate_paymentoptions')}</span>
            <div className="flex-pad" />
            <button className="button icon-button" type="button" onClick={() => sendDonate()}>
              <IoLogoPaypal />
              <span>{t('donate_continue')}</span>
            </button>
          </div>
        </div>
      </div>
    </BasePageLayout >
  );
};

export default PageDonate;
