import {ReactNode, useEffect} from 'react';
import {IoClose} from 'react-icons/io5';
import './Modal.css';

export const modalEffects = (open: boolean) => {
  if (open) {
    const scrollY = window.scrollY;
    document.body.classList.add('modal-open');
    document.body.style.top = `-${scrollY}px`;
  } else {
    const scrollY = document.body.style.top;
    document.body.classList.remove('modal-open');
    document.body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
  }
};

interface IModalProps {
  children: ReactNode,
  isOpen: boolean,
  id?: string,
  /** takes precendence over title, closes modal on any click */
  fillContent?: boolean,
  /** not evalueted if fillContent */
  title?: string,
  className?: string,
  classNameInner?: string,
  onClose: () => void
}
const Modal = (props: IModalProps) => {
  useEffect(() => {
    modalEffects(props.isOpen);
  }, [props.isOpen]);

  if (props.fillContent) return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div id={props.id} className={`modal-container${props.className ? ` ${props.className}` : ''}${props.isOpen ? ' open' : ''}`}
      onClick={event => {
        event.stopPropagation();
        props.onClose();
      }}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={`modal-content fill-content${props.classNameInner ? ` ${props.classNameInner}` : ''}`} onClick={e => e.stopPropagation()}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <span role="button" tabIndex={0} onClick={event => {
          event.stopPropagation();
          props.onClose();
        }}
          style={{position: 'fixed', margin: 'calc(var(--margin-base) * 2)', top: 0, right: 0, fontSize: '2em', cursor: 'pointer', zIndex: 999}}>
          <IoClose />
        </span>
        {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
        <div className="fill-content-wrapper" onClick={event => {
          event.stopPropagation();
          props.onClose();
        }}>
          {props.children}
        </div>
      </div>
    </div>
  );

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div id={props.id} className={`modal-container${props.className ? ` ${props.className}` : ''}${props.isOpen ? ' open' : ''}`}
      onClick={event => {
        event.stopPropagation();
        props.onClose();
      }}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={`modal-content${props.classNameInner ? ` ${props.classNameInner}` : ''}`} onClick={e => e.stopPropagation()}>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <span role="button" tabIndex={0} onClick={event => {
          event.stopPropagation();
          props.onClose();
        }}
          style={{position: 'fixed', margin: 'calc(var(--margin-base) * 2)', top: 0, right: 0, fontSize: '2em', cursor: 'pointer', zIndex: 999}}>
          <IoClose />
        </span>
        <h2>{props.title}</h2>
        {props.children}
      </div>
    </div>
  );
};

export default Modal;
