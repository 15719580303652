import {Dispatch, ReactNode, SetStateAction, createContext, useMemo, useState} from 'react';

interface IGlobalState {
  /** base-url of the site (used for dynamic src pathing) */
  baseURL: string,
  /** denotes whether the user is guest or logged in */
  isLoggedIn: boolean,
  /** denotes whether login is currently in progress */
  isLoginLoading: boolean,
  username: string | null,
  password: string | null,

  /** GUID of account on gameserver */
  userid: number,
  /** user-changable nickname to be displayed on site (eg. top voters) */
  nickname: string | null,
  email: string | null,
  /** donation points */
  dp: number,
  /** vote points */
  vp: number,
  joindate: Date | null,
  lastLogin: Date | null,
  /** total played time in seconds */
  totaltime: number,

  lastArmorySearch: string,
  lastArmoryTab: 'chars' | 'items'
}

export const defaultState: IGlobalState = {
  baseURL: '',
  isLoggedIn: false,
  isLoginLoading: false,
  username: null,
  password: null,
  userid: 0,
  nickname: null,
  email: null,
  dp: 0,
  vp: 0,
  joindate: null,
  lastLogin: null,
  totaltime: 0,
  lastArmorySearch: '',
  lastArmoryTab: 'chars'
};

export const GlobalContext = createContext<{globalState: IGlobalState, setGlobalState: Dispatch<SetStateAction<IGlobalState>>}>({globalState: defaultState, setGlobalState: () => {}});

const GlobalContextProvider = (props: {children: ReactNode}) => {
  const baseURL = `${process.env.NODE_ENV === 'development' ? 'http' : 'https'}://${window.location.host}/`;

  const [globalState, setGlobalState] = useState({...defaultState, baseURL: baseURL});
  const providerValue = useMemo(() =>
  ({
    globalState,
    setGlobalState
  }), [globalState, setGlobalState]);

  return (
    <GlobalContext.Provider value={providerValue}>
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
