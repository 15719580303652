import {CSSProperties, useContext} from 'react';
import {GlobalContext} from '../helpers/globalContext';

interface IPictureProps {
  /** relative path to the image, excluding 'assets/' */
  path: string,
  alt: string,
  lazy?: boolean,
  id?: string,
  className?: string,
  title?: string,
  style?: CSSProperties
}
const Picture = (props: IPictureProps) => {
  const {globalState} = useContext(GlobalContext);

  if (globalState.baseURL === '') return (
    <img src="" alt="" />
  );

  return (
    <picture>
      <source srcSet={`${globalState.baseURL}assets/webp/${props.path}.webp`} />
      <img loading={props.lazy ? 'lazy' : 'eager'}
        id={props.id}
        src={`${globalState.baseURL}assets/${props.path}.png`}
        alt={props.alt}
        className={props.className}
        title={props.title}
        style={props.style} />
    </picture>
  );
};

export default Picture;
