import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import BasePageLayout from './BasePageLayout';
import LoadingIcon from '../components-elements/LoadingIcon';
import WarningBanner from '../components-elements/WarningBanner';
import './PageVote.css';
import Picture from '../components-elements/Picture';


interface IVoteSite {
  id: number,
  name: string,
  displayname: string,
  url: string,
  callback: string,
  cooldown: number,
  reward: number
}

const VoteButton = (props: {
  site: IVoteSite,
  user: {
    accountID: number,
    username: string,
    password: string,
  },
  onVoteSuccess: (id: number, cooldown: number, newvp: number) => void,
  onVoteFailure: (id: number, cooldown: boolean) => void
}) => {
  let timer: any;
  let timerRegistered = false;
  let cooldown = props.site.cooldown;
  let isRunningClickEvent = false;  // used to immediately lock any further interactions on click
  const {t} = useTranslation();
  const [isDisabled, setIsDisabled] = useState(cooldown > 0);
  const [remaining, setRemaining] = useState(cooldown);

  const setTimer = () => {
    timer = setInterval(() => {
      cooldown--;
      setRemaining(cooldown);
      if (cooldown <= 0) {
        clearInterval(timer);
        setIsDisabled(false);
        timerRegistered = false;
      }
    }, 1000);
  };

  const onVote = () => {
    if (isRunningClickEvent) return;
    isRunningClickEvent = true;

    ZULL_API.POST({
      endpoint: `account/${props.user.accountID}/vote`,
      authUser: props.user.username,
      authPass: props.user.password,
      body: JSON.stringify({site: props.site.id})
    }).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        props.onVoteFailure(props.site.id, res.status === 403 && !!res.body && res.body.toLowerCase().includes('cooldown'));
        return;
      }
      setIsDisabled(true);
      const ret = JSON.parse(res.body) as {site: number, cooldown: number, vp: number};
      cooldown = ret.cooldown;
      setRemaining(ret.cooldown);
      props.onVoteSuccess(ret.site, ret.cooldown, ret.vp);
      if (!timerRegistered) setTimer();
      isRunningClickEvent = false;
    });
  };

  useEffect(() => {
    if (!timerRegistered && cooldown > 0) {
      timerRegistered = true;
      setTimer();

      return () => {  // fires on component unmount
        clearInterval(timer);
        timerRegistered = false;
      };
    }
  }, []);

  if (isDisabled) return (
    <div className="vote-link disabled">
      <Picture path={`vote/${props.site.name}`} alt={props.site.displayname} />
      <span className="name">{props.site.displayname}</span>
      <span className="cooldown">{ZULL_DATETIME.timeFormat(remaining, ZULL_DATETIME.TimeFormat.HHmmss)}</span>
      <span className="reward">{t('reward')}: {props.site.reward}VP</span>
    </div>
  );

  return (
    <a target="_blank" href={props.site.url} rel="noopener noreferrer"
      className="vote-link"
      onClick={() => onVote()} onMouseUp={(e) => {if (e.button === 1) onVote();}}>
      <Picture path={`vote/${props.site.name}`} alt={props.site.displayname} />
      <span className="name">{props.site.displayname}</span>
      <span className="reward">{t('reward')}: {props.site.reward}VP</span>
    </a>
  );
};





const PageVote = () => {
  const {t} = useTranslation();
  const {globalState, setGlobalState} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [sites, setSites] = useState<IVoteSite[]>([]);

  const getSites = () => {
    if (!globalState.isLoggedIn || globalState.isLoginLoading) return;
    setIsLoading(true);

    let sitesResponse: any[] = [];
    ZULL_API.GET({endpoint: 'public/getvotesites'}).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        return;
      }
      if (!globalState.username || !globalState.password) return;
      sitesResponse = JSON.parse(res.body) as {
        id: number,
        name: string,
        displayname: string,
        url: string,
        callback: string,
        reward: number
      }[];
      return ZULL_API.GET({
        endpoint: `account/${globalState.userid}/getvotecooldowns`,
        authUser: globalState.username,
        authPass: globalState.password
      });
    }).then(res => {
      if (!res || !res.ok) {
        console.error(res);
        return;
      }
      if (res.status === 204) {
        for (let i = 0; i < sitesResponse.length; i++) {
          sitesResponse[i].cooldown = 0;
        }
      } else {
        if (!res.body) return;
        const cooldowns = JSON.parse(res.body) as {id: number, cooldown: number}[];
        const findCooldown = (id: number) => cooldowns.find(x => x.id === id)?.cooldown;
        for (let i = 0; i < sitesResponse.length; i++) {
          const cd = findCooldown(sitesResponse[i].id);
          sitesResponse[i].cooldown = cd ?? 0;
        }
      }

      setSites(sitesResponse);
      setIsLoading(false);
    });
  };

  const onVoteSuccess = (id: number, cooldown: number, newvp: number) => {
    setIsError(false);
    setGlobalState(state => ({...state, vp: newvp}));
    getSites();
  };

  const onVoteFailure = (id: number, cooldown: boolean) => {
    setIsError(!cooldown);
    getSites();
  };

  useEffect(() => getSites(), []);
  useEffect(() => getSites(), [globalState.isLoggedIn, globalState.isLoginLoading]);

  if (!globalState.isLoggedIn || !globalState.username || !globalState.password) return (
    <BasePageLayout title={t('nav_vote')}>
      <WarningBanner type="error">
        {t('account_error_needlogin')}
      </WarningBanner>
    </BasePageLayout>
  );
  if (isLoading || globalState.isLoginLoading) return (
    <BasePageLayout title={t('nav_vote')}>
      <LoadingIcon />
    </BasePageLayout>
  );

  return (
    <BasePageLayout title={t('nav_vote')}>
      <div>
        <span id="vote-line1">{t('vote_1')} <strong style={{color: 'var(--text-color-primary)', textTransform: 'uppercase', textShadow: 'var(--text-glow)', whiteSpace: 'nowrap'}}>{t('vote_points')}</strong>.</span>
        <span id="vote-line2">{t('vote_2')}</span>

        <WarningBanner type="error" show={isError}>
          {t('error_generic')}
        </WarningBanner>

        <div id="vote-links">
          {/* xxx ?? '' is no issue since it's checked in if() return above. Typescript just doesn't check it. */
            sites.map(x => (
              <VoteButton key={x.id} site={x}
                user={{accountID: globalState.userid, username: globalState.username ?? '', password: globalState.password ?? ''}}
                onVoteSuccess={onVoteSuccess} onVoteFailure={onVoteFailure} />
            ))
          }
        </div>
      </div>
    </BasePageLayout>
  );
};

export default PageVote;
