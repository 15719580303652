import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import useStorage from '../helpers/useStorage';
import {modalEffects} from '../components-elements/Modal';
import './CookieConsent.css';

const CookieConsent = () => {
  const {t} = useTranslation();
  const {cookieConsentStorage} = useStorage();
  const [isOpen, setIsOpen] = useState(true);

  const showHide = (open: boolean) => {
    setIsOpen(open);
    modalEffects(open);
  };

  useEffect(() => {
    showHide(!cookieConsentStorage.getVal());
  }, []);

  return (
    <div id="cookie-consent-background" hidden={!isOpen}>
      <div id="cookie-consent-container">
        <h2>Cookies</h2>
        {t('cookie_consent')} <Link to="/privacy">{t('nav_privacy')}</Link>
        <div id="cookie-consent-okay" className="button primary" role="button"
          onClick={() => {
            cookieConsentStorage.setVal(true);
            showHide(false);
          }} tabIndex={0}>
          {t('ok')}
        </div>
      </div>
    </div>
  );
};

export default CookieConsent;
