import {useContext, useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoArrowBack} from 'react-icons/io5';
import {ZULL_API, ZULL_ITEM} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import WarningBanner from '../components-elements/WarningBanner';
import LoadingIcon from '../components-elements/LoadingIcon';
import BasePageLayout from './BasePageLayout';
import './PageArmory.css';

const PageArmoryItem = () => {
  const {id} = useParams();
  const {t, i18n} = useTranslation();
  const {globalState} = useContext(GlobalContext);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ZULL_ITEM.ItemData.TItemData | null>(null);

  const getData = () => {
    if (!globalState.isLoggedIn || globalState.isLoginLoading || !globalState.username || !globalState.password) return;
    setIsLoading(true);

    ZULL_API.POST({
      endpoint: `armory/${id}/item`,
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({lang: i18n.language.substring(0, 2)})
    }).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        return;
      }
      setData(JSON.parse(res.body) as ZULL_ITEM.ItemData.TItemData);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    getData();
    const updateWidth = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, []);
  useEffect(() => getData(), [globalState.isLoggedIn, globalState.isLoginLoading]);

  if (!globalState.isLoggedIn || !globalState.username || !globalState.password) return (
    <BasePageLayout title={t('nav_vote')}>
      <WarningBanner type="error">
        {t('account_error_needlogin')}
      </WarningBanner>
    </BasePageLayout>
  );
  if (isLoading || globalState.isLoginLoading || data === null) return (
    <BasePageLayout title={t('nav_vote')}>
      <LoadingIcon />
    </BasePageLayout>
  );

  return (
    <BasePageLayout title={t('nav_armory')} noPadding={windowWidth < 360}>
      <div className="armory-page">
        <Link to="/armory" className="back"><IoArrowBack /> Armory</Link>
        <div className="item-wrapper">
          <ZULL_ITEM.ItemIcon item={data} noTooltip />
          <ZULL_ITEM.ItemTooltip item={data} de={i18n.language.substring(0, 2) === 'de'} />
        </div>
      </div>
    </BasePageLayout>
  );
};

export default PageArmoryItem;
