import {ChangeEvent, ReactNode} from 'react';
import './Input.css';

interface IInputProps {
  id: string,
  label?: string,
  text: string,
  noLabel?: boolean,
  icon?: ReactNode,
  type?: 'text' | 'email' | 'password' | 'number',
  isError?: boolean,
  autocomplete?: 'on' | 'off' | 'username' | 'email' | 'new-password' | 'current-password',
  minLength?: number,
  maxLength?: number,
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Input = (props: IInputProps) => (
  <div className={`input-wrapper${props.isError ? ' error' : ''}${props.noLabel ? ' nolabel' : ''}`}>
    {!props.noLabel && <span className="input-label">{props.label}</span>}
    <input id={props.id} type={props.type ? props.type : 'text'} value={props.text}
      autoComplete={props.autocomplete} onChange={props.onChange}
      style={{paddingInlineStart: props.icon ? '2em' : '1em', width: props.icon ? 'calc(100% - 12px - 2em)' : 'calc(100% - 12px - 1em)'}}
      minLength={props.minLength || undefined} maxLength={props.maxLength || undefined} />
    <span hidden={props.icon === undefined} className="input-icon">{props.icon}</span>
  </div>
);

export default Input;
