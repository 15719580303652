import {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {IoLockClosed} from 'react-icons/io5';
import {ZULL_API} from 'zull-common-js';
import Input from '../components-elements/Input';
import WarningBanner from '../components-elements/WarningBanner';
import BasePageLayout from './BasePageLayout';
import {accountPrerequisites} from './PageRegister';

const PageResetPW = () => {
  const {t} = useTranslation();
  const [resetPW, setResetPW] = useState('');
  const [resetPWConfirm, setResetPWConfirm] = useState('');
  const [resetPWError, setResetPWError] = useState(false);
  const [resetPWConfirmError, setResetConfirmPWError] = useState(false);
  const [resetPWErrorMessage, setResetPWErrorMessage] = useState('');
  const [resetPWSuccess, setResetPWSuccess] = useState(false);

  if (resetPWSuccess) return (
    <BasePageLayout title={t('account_resetpw')}>
      <WarningBanner type="info">
        {t('success')}
      </WarningBanner>
    </BasePageLayout>
  );

  const changePassword = () => {
    setResetPWError(!resetPW);
    setResetConfirmPWError(!resetPWConfirm || resetPWConfirm !== resetPW);
    if (!resetPW || !resetPWConfirm || resetPWConfirm !== resetPW) return;

    const token = window.location.href.substring(window.location.href.lastIndexOf('/') + 1);
    if (!token) {
      setResetPWErrorMessage(t('account_error_resetpw_tokeninvalid'));
      return;
    }

    ZULL_API.POST({
      endpoint: `/account/${token}/finalizeresetpw`,
      body: JSON.stringify({newPW: resetPW})
    }).then(res => {
      if (!res.ok) {
        let newErrorMessage = '';
        if (res.status === 500) newErrorMessage = t('error_generic');
        if (res.status === 404 || res.body?.includes('token')) newErrorMessage = t('account_error_resetpw_tokeninvalid');
        setResetPWErrorMessage(newErrorMessage);
        return;
      }
      setResetPWSuccess(true);
    });
  };

  return (
    <BasePageLayout title={t('account_resetpw')}>
      <div id="page-resetpw-content">
        <WarningBanner type="error" show={!!resetPWErrorMessage}>
          {resetPWErrorMessage}
        </WarningBanner>
        <form onSubmit={e => {e.preventDefault(); changePassword();}}>
          <Input label={t('account_password')} text={resetPW} onChange={e => setResetPW(e.target.value.trim())}
            icon={<IoLockClosed />} isError={resetPWError} type="password"
            id="resetpw-password" minLength={accountPrerequisites.passwordMin} maxLength={accountPrerequisites.passwordMax}
            autocomplete="new-password" />
          <Input label={t('account_password_confirm')} text={resetPWConfirm} onChange={e => setResetPWConfirm(e.target.value.trim())}
            icon={<IoLockClosed />} isError={resetPWConfirmError} type="password"
            id="resetpw-password-confirm" autocomplete="new-password" />
          <div id="recaptcha-container" />
          <button type="submit" className="button primary">
            {t('account_resetpw')}
          </button>
        </form>
      </div>
    </BasePageLayout>
  );
};

export default PageResetPW;
