import Picture from './Picture';

type TIconData = {
  type: 'faction',
  /** 0 = Horde, 1 = Alliance */
  value: 0 | 1
} | {
  type: 'class',
  value: string
}
interface IIconProps {
  data: TIconData,
  id?: string,
  className?: string
}

const Icon = (props: IIconProps) => {
  const getPath = (data: TIconData): string => {
    try {
      switch (data.type) {
        case 'faction':
          switch (data.value) {
            case 0: return 'horde';
            case 1: return 'alliance';
            default: throw new Error('Icon data error');
          }
        case 'class':
          switch (data.value) {
            case 'Warrior': return 'warrior';
            case 'Paladin': return 'paladin';
            case 'Hunter': return 'hunter';
            case 'Rogue': return 'rogue';
            case 'Priest': return 'priest';
            case 'Death Knight': return 'deathknight';
            case 'Shaman': return 'shaman';
            case 'Mage': return 'mage';
            case 'Warlock': return 'warlock';
            case 'Monk': return 'monk';
            case 'Druid': return 'druid';
            case 'Demon Hunter': return 'demonhunter';
            case 'Evoker': return 'evoker';
            default: throw new Error('Icon data error');
          }

        default: throw new Error('Icon type error');
      }
    } catch (e) {
      console.error(e, data);
      return '';
    }
  };

  return <Picture lazy
    path={`icons/${getPath(props.data)}`}
    alt="Icon"
    id={props.id}
    className={props.className}
    style={{borderRadius: 'var(--border-radius)', boxShadow: 'var(--box-glow-small)'}} />;
};

export default Icon;
