import {useContext, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoLockClosed, IoLogOutOutline, IoPencil} from 'react-icons/io5';
import {ZULL_API, ZULL_DATETIME} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import useLoginManager from '../helpers/useLoginManager';
import BasePageLayout from './BasePageLayout';
import {accountPrerequisites} from './PageRegister';
import WarningBanner from '../components-elements/WarningBanner';
import LoadingIcon from '../components-elements/LoadingIcon';
import './PageAccount.css';
import Modal from '../components-elements/Modal';
import Input from '../components-elements/Input';
import Picture from '../components-elements/Picture';

const PageAccount = () => {
  const {t, i18n} = useTranslation();
  const {globalState, setGlobalState} = useContext(GlobalContext);
  const {logout} = useLoginManager();

  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [changePasswordOld, setChangePasswordOld] = useState('');
  const [changePasswordNew, setChangePasswordNew] = useState('');
  const [changePasswordNewConfirm, setChangePasswordNewConfirm] = useState('');
  const [changePasswordOldError, setChangePasswordOldError] = useState(false);
  const [changePasswordNewError, setChangePasswordNewError] = useState(false);
  const [changePasswordNewConfirmError, setChangePasswordNewConfirmError] = useState(false);
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);
  const [changePasswordError, setChangePasswordError] = useState('');

  const [changeEmailOpen, setChangeEmailOpen] = useState(false);
  const [changeEmailNew, setChangeEmailNew] = useState('');
  const [changeEmailNewError, setChangeEmailNewError] = useState(false);
  const [changeEmailSuccess, setChangeEmailSuccess] = useState(false);
  const [changeEmailError, setChangeEmailError] = useState('');

  const [changeNickOpen, setChangeNickOpen] = useState(false);
  const [changeNickNew, setChangeNickNew] = useState('');
  const [changeNickNewError, setChangeNickNewError] = useState(false);
  const [changeNickSuccess, setChangeNickSuccess] = useState(false);
  const [changeNickError, setChangeNickError] = useState('');

  if (globalState.isLoginLoading) return (
    <BasePageLayout title={t('account')}>
      <LoadingIcon />
    </BasePageLayout>
  );

  if (!globalState.isLoggedIn) return (
    <BasePageLayout title={t('account')}>
      <WarningBanner type="error">
        {t('account_error_needlogin')}
      </WarningBanner>
    </BasePageLayout>
  );

  return (
    <BasePageLayout title={t('account')}>
      <div id="account-page-content">
        <h2>{globalState.username}</h2>
        <div id="account-page-data">
          <div className="account-page-data-column">
            <div>{t('account_email')} <span>{
              globalState.email?.replace(
                globalState.email.substring(
                  globalState.email.lastIndexOf('@') / 2,
                  globalState.email.lastIndexOf('@')
                ), '**')}</span></div>
            <div>{t('account_id')} <span>{globalState.userid}</span></div>
            <div>{t('account_nick')} <span>{globalState.nickname}</span></div>
          </div>
          <div className="account-page-data-column">
            <div>{t('account_joindate')} <span>{ZULL_DATETIME.dateFormat({date: globalState.joindate, langCode: i18n.language.substring(0, 2)})}</span></div>
            <div>{t('account_lastlogin')} <span>{ZULL_DATETIME.dateFormat({date: globalState.lastLogin, langCode: i18n.language.substring(0, 2)})}</span></div>
          </div>
        </div>
        <div id="account-page-sections">
          <div className="account-page-section">
            <h3>{t('account_balance')}</h3>
            <div>
              <Link to="/donate" className="button icon-button">
                <Picture path="dp" alt="" />
                <span>{t('nav_donate')}: {globalState.dp} DP</span>
              </Link>
              <Link to="/vote" className="button icon-button">
                <Picture path="vp" alt="" />
                <span>{t('nav_vote')}: {globalState.vp} VP</span>
              </Link>
            </div>
          </div>

          <div className="account-page-section">
            <h3>{t('account')}</h3>
            <div>
              <button type="button" className="button icon-button large-icon" onClick={() => setChangePasswordOpen(true)}>
                <IoLockClosed />
                <span>{t('account_changepw')}</span>
              </button>
              <button type="button" className="button icon-button large-icon" onClick={() => setChangeEmailOpen(true)}>
                <IoPencil />
                <span>{t('account_changeemail')}</span>
              </button>
              <button type="button" className="button icon-button large-icon" onClick={() => setChangeNickOpen(true)}>
                <IoPencil />
                <span>{t('account_changedisplayname')}</span>
              </button>
              <button type="button" className="button icon-button large-icon" onClick={() => logout()}>
                <IoLogOutOutline />
                <span>{t('account_logout')}</span>
              </button>
            </div>
          </div>
        </div>


        {/* Change password */}

        <Modal title={t('account_changepw')} isOpen={changePasswordOpen} onClose={() => setChangePasswordOpen(false)}>
          {changePasswordSuccess ?
            <WarningBanner type="info">
              {t('success')}
            </WarningBanner>
            : (
              <div>
                <WarningBanner type="error" show={!!changePasswordError}>
                  {changePasswordError}
                </WarningBanner>
                <form onSubmit={e => {
                  e.preventDefault();
                  let newErrorMessage = '';
                  if (!changePasswordOld || !changePasswordNew || !changePasswordNewConfirm) {
                    setChangePasswordOldError(!changePasswordOld);
                    setChangePasswordNewError(!changePasswordNew);
                    setChangePasswordNewConfirmError(!changePasswordNewConfirm);
                    newErrorMessage = t('account_error_nopass');
                    return;
                  }
                  if (changePasswordNew.length < accountPrerequisites.passwordMin || changePasswordNew.length > accountPrerequisites.passwordMax) {
                    setChangePasswordNewError(true);
                    return;
                  }
                  if (changePasswordNew !== changePasswordNewConfirm) {
                    setChangePasswordNewConfirmError(true);
                    return;
                  }
                  setChangePasswordError(newErrorMessage);
                  setChangePasswordOldError(false);
                  setChangePasswordNewError(false);
                  setChangePasswordNewConfirmError(false);
                  if (!globalState.username || !globalState.password) return;
                  ZULL_API.POST({
                    endpoint: `account/${globalState.userid}/changepw`,
                    authUser: globalState.username,
                    authPass: globalState.password,
                    body: JSON.stringify({newPW: changePasswordNew, langCode: i18n.language.substring(0, 2)})
                  }).then(res => {
                    // all errors are bugs because the user can't make wrong inputs at this point
                    if (!res.ok) {
                      setChangePasswordError(t('error_generic'));
                      return;
                    }
                    setChangePasswordSuccess(true);
                  });
                }}>
                  <Input id="account-changepw-old" label={t('account_oldpw')} text={changePasswordOld}
                    isError={changePasswordOldError} onChange={e => setChangePasswordOld(e.target.value.trim())} type="password"
                    autocomplete="current-password" />
                  <Input id="account-changepw-new" label={t('account_newpw')} text={changePasswordNew}
                    isError={changePasswordNewError} onChange={e => setChangePasswordNew(e.target.value.trim())} type="password"
                    minLength={accountPrerequisites.passwordMin} maxLength={accountPrerequisites.passwordMax}
                    autocomplete="new-password" />
                  <Input id="account-changepw-newconfirm" label={t('account_newpwconfirm')} text={changePasswordNewConfirm}
                    isError={changePasswordNewConfirmError} onChange={e => setChangePasswordNewConfirm(e.target.value.trim())} type="password"
                    minLength={accountPrerequisites.passwordMin} maxLength={accountPrerequisites.passwordMax}
                    autocomplete="new-password" />
                  <button type="submit" className="button primary">
                    {t('account_changepw')}
                  </button>
                </form>
              </div>
            )}
        </Modal>


        {/* Change email */}

        <Modal title={t('account_changeemail')} isOpen={changeEmailOpen} onClose={() => setChangeEmailOpen(false)}>
          {changeEmailSuccess ?
            <WarningBanner type="info">
              {t('account_changeemail_pending')}
            </WarningBanner>
            : (
              <div>
                <WarningBanner type="error" show={!!changeEmailError}>
                  {changeEmailError}
                </WarningBanner>
                <form onSubmit={e => {
                  e.preventDefault();
                  setChangeEmailNewError(!changeEmailNew);
                  if (!changeEmailNew) return;
                  if (!globalState.username || !globalState.password) return;
                  ZULL_API.POST({
                    endpoint: `account/${globalState.userid}/changeemail`,
                    authUser: globalState.username,
                    authPass: globalState.password,
                    body: JSON.stringify({newEmail: changeEmailNew, langCode: i18n.language.substring(0, 2)})
                  }).then(res => {
                    if (!res.ok) {
                      if (res.body?.toLowerCase().includes('email taken')) {
                        setChangeEmailError(t('account_error_emailtaken'));
                      } else if (res.body?.toLowerCase().includes('already pending')) {
                        setChangeEmailError(t('account_error_emailtaken'));
                      } else {
                        setChangeEmailError(t('error_generic'));
                      }
                      return;
                    }
                    setChangeEmailError('');
                    setChangeEmailSuccess(true);
                  });
                }}>
                  <Input id="account-changeemail-new" label={t('account_newemail')} text={changeEmailNew}
                    isError={changeEmailNewError} onChange={e => setChangeEmailNew(e.target.value.trim())} type="email"
                    autocomplete="email" />
                  <button type="submit" className="button primary">
                    {t('account_changeemail')}
                  </button>
                </form>
              </div>
            )}
        </Modal>


        {/* Change nick */}

        <Modal title={t('account_changedisplayname')} isOpen={changeNickOpen} onClose={() => setChangeNickOpen(false)}>
          {changeNickSuccess ?
            <WarningBanner type="info">
              {t('success')}
            </WarningBanner>
            : (
              <div>
                <WarningBanner type="error" show={!!changeNickError}>
                  {changeNickError}
                </WarningBanner>
                <form onSubmit={e => {
                  e.preventDefault();
                  setChangeNickNewError(!changeNickNew);
                  if (!changeNickNew) return;
                  if (!globalState.username || !globalState.password) return;
                  ZULL_API.POST({
                    endpoint: `account/${globalState.userid}/changenick`,
                    authUser: globalState.username,
                    authPass: globalState.password,
                    body: JSON.stringify({newNick: changeNickNew})
                  }).then(res => {
                    if (!res.ok) {
                      if (res.body?.toLowerCase().includes('name taken')) {
                        setChangeNickError(t('account_error_usertaken'));
                        setChangeNickNewError(true);
                      } else {
                        setChangeNickError(t('error_generic'));
                      }
                      return;
                    }
                    setChangeNickError('');
                    setChangeNickSuccess(true);
                    setGlobalState({...globalState, nickname: changeNickNew});
                  });
                }}>
                  <Input id="account-changenick-new" label={t('account_nick')} text={changeNickNew}
                    isError={changeNickNewError} onChange={e => setChangeNickNew(e.target.value.trim())} type="text"
                    autocomplete="username" />
                  <button type="submit" className="button primary">
                    {t('account_changedisplayname')}
                  </button>
                </form>
              </div>
            )}
        </Modal>
      </div>
    </BasePageLayout>
  );
};

export default PageAccount;
