import {ChangeEvent, ReactNode} from 'react';
import './Checkbox.css';

interface ICheckboxProps {
  children?: ReactNode,
  label?: string,
  id?: string
  checked: boolean,
  // eslint-disable-next-line no-unused-vars
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}

const Checkbox = (props: ICheckboxProps) => (
  <div className="checkbox-wrapper" id={props.id}>
    <input type="checkbox" checked={props.checked} onChange={props.onChange} />
    {props.label && <span className="input-label">{props.label}</span>}
    {props.children && <span className="input-label">{props.children}</span>}
  </div>
);

export default Checkbox;
