import {useContext, useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoCaretBack, IoCaretForward} from 'react-icons/io5';
import {ZULL_API, ZULL_COMP, ZULL_ITEM} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import LoadingIcon from '../components-elements/LoadingIcon';
import WarningBanner from '../components-elements/WarningBanner';
import BasePageLayout from './BasePageLayout';
import Input from '../components-elements/Input';
import Icon from '../components-elements/Icon';

type ArmoryTab = 'chars' | 'items';
type SortByChars = 'name' | 'race' | 'class' | 'level' | 'zone';
interface ICharEntry {
  id: number,
  name: string,
  race: string,
  /** 0 = Horde, 1 = Alliance */
  faction: 0 | 1,
  class: string,
  level: number,
  zone: string
}
type SortByItems = 'name' | 'itemlevel';

const PageArmory = () => {
  const pageSize = 20;

  const {t, i18n} = useTranslation();
  const {globalState, setGlobalState} = useContext(GlobalContext);
  const isMobile = !window.matchMedia('(min-width: 36em)').matches;
  const [isLoading, setIsLoading] = useState(false);
  const [currentSearch, setCurrentSearch] = useState(globalState.lastArmorySearch);
  const [tab, setTab] = useState<ArmoryTab>(globalState.lastArmoryTab);

  const [numCharResults, setNumCharResults] = useState(0);
  const [chars, setChars] = useState<ICharEntry[][]>([]);
  const [charsPage, setCharsPage] = useState<ICharEntry[]>([]);
  const [currentPageChars, setCurrentPageChars] = useState(1);
  const [sortByChars, setSortByChars] = useState<SortByChars>('name');
  const [sortAscChars, setSortAscChars] = useState(true);

  const [numItemResults, setNumItemResults] = useState(0);
  const [items, setItems] = useState<ZULL_ITEM.ItemData.TItemData[][]>([]);
  const [itemsPage, setItemsPage] = useState<ZULL_ITEM.ItemData.TItemData[]>([]);
  const [currentPageItems, setCurrentPageItems] = useState(1);
  const [sortByItems, setSortByItems] = useState<SortByItems>('name');
  const [sortAscItems, setSortAscItems] = useState(true);

  const sortChars = (arr: ICharEntry[], by: SortByChars, asc: boolean) => arr.sort((a, b) => {
    if (asc) return a[by] < b[by] ? -1 : 1;
    return a[by] > b[by] ? -1 : 1;
  });
  const setPageChars = (page: number, chunks?: ICharEntry[][]) => {
    if (page < 1 || (chunks ? page > chunks.length : page > chars.length)) return;
    setCurrentPageChars(page);
    setCharsPage(chunks ? chunks[page - 1] : chars[page - 1]);  // page is 1-based, arrays 0-based
  };

  const sortItems = (arr: ZULL_ITEM.ItemData.TItemData[], by: SortByItems, asc: boolean) => arr.sort((a, b) => {
    if (asc) return a[by] < b[by] ? -1 : 1;
    return a[by] > b[by] ? -1 : 1;
  });
  const setPageItems = (page: number, chunks?: ZULL_ITEM.ItemData.TItemData[][]) => {
    if (page < 1 || (chunks ? page > chunks.length : page > items.length)) return;
    setCurrentPageItems(page);
    setItemsPage(chunks ? chunks[page - 1] : items[page - 1]);  // page is 1-based, arrays 0-based
  };

  const chunkify = (arr: ICharEntry[] | ZULL_ITEM.ItemData.TItemData[]) => {
    const chunks: (typeof arr)[] = [];
    for (let i = 0; i < arr.length; i += pageSize) {
      chunks.push(arr.slice(i, i + pageSize));
    }
    return chunks;
  };



  // ///////////////////
  const search = (pattern: string, currentTab: ArmoryTab) => {
    if (!globalState.isLoggedIn || globalState.isLoginLoading || !globalState.username || !globalState.password) return;
    if (pattern.length < 3) return;
    setIsLoading(true);
    ZULL_API.POST({
      endpoint: `armory/${pattern}/search${currentTab}`,
      authUser: globalState.username,
      authPass: globalState.password,
      body: JSON.stringify({lang: i18n.language.substring(0, 2)})
    }).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        return;
      }
      if (currentTab === 'chars') {
        const current = sortChars(JSON.parse(res.body) as ICharEntry[], sortByChars, sortAscChars);
        const chunks = chunkify(current) as ICharEntry[][];
        setChars(chunks);
        setPageChars(1, chunks);
        setNumCharResults(current.length);
      }
      if (currentTab === 'items') {
        const current = sortItems(JSON.parse(res.body) as ZULL_ITEM.ItemData.TItemData[], sortByItems, sortAscItems);
        const chunks = chunkify(current) as ZULL_ITEM.ItemData.TItemData[][];
        setItems(chunks);
        setPageItems(1, chunks);
        setNumItemResults(current.length);
      }
      setIsLoading(false);
      setGlobalState(state => ({...state, lastArmorySearch: pattern, lastArmoryTab: currentTab}));
    });
  };
  // ///////////////////



  const setSortChars = (key: SortByChars) => {
    let asc = sortAscChars;
    let by = sortByChars;
    if (sortByChars === key) {
      setSortAscChars(!asc);
      asc = !asc;
    } else {
      setSortAscChars(true);
      asc = true;
      setSortByChars(key);
      by = key;
    }
    const current = chunkify(sortChars(chars.flat(), by, asc)) as ICharEntry[][];
    setChars(current);
    setPageChars(currentPageChars, current);
  };
  const setSortItems = (key: SortByItems) => {
    let asc = sortAscItems;
    let by = sortByItems;
    if (sortByItems === key) {
      setSortAscItems(!asc);
      asc = !asc;
    } else {
      setSortAscItems(true);
      asc = true;
      setSortByItems(key);
      by = key;
    }
    const current = chunkify(sortItems(items.flat(), by, asc)) as ZULL_ITEM.ItemData.TItemData[][];
    setItems(current);
    setPageItems(currentPageItems, current);
  };

  const switchTab = (newTab: ArmoryTab) => {
    setTab(newTab);
    search(currentSearch, newTab);
  };



  useEffect(() => {
    if (globalState.lastArmorySearch) search(globalState.lastArmorySearch, globalState.lastArmoryTab);
  }, []);

  if (!globalState.isLoggedIn || !globalState.username || !globalState.password) return (
    <BasePageLayout title={t('nav_armory')}>
      <WarningBanner type="error">
        {t('account_error_needlogin')}
      </WarningBanner>
    </BasePageLayout>
  );
  if (isLoading || globalState.isLoginLoading) return (
    <BasePageLayout title={t('nav_armory')}>
      <LoadingIcon />
    </BasePageLayout>
  );



  return (
    <BasePageLayout title={t('nav_armory')}>
      <div id="armory-wrapper">
        <form onSubmit={e => {e.preventDefault(); search(currentSearch, tab);}}>
          <Input id="armory-search" noLabel text={currentSearch}
            onChange={e => setCurrentSearch(e.target.value)} type="text"
            autocomplete="off" minLength={3} maxLength={64} />
          <button type="submit" className="button primary" style={{marginTop: 0}}>
            {t('search')}
          </button>
        </form>

        <div id="armory-results">
          <span id="tab-buttons">
            <button type="button" className={`button${tab === 'chars' ? ' primary' : ''}`} onClick={() => switchTab('chars')}>{t('chars')}</button>
            <button type="button" className={`button${tab === 'items' ? ' primary' : ''}`} onClick={() => switchTab('items')}>{t('items')}</button>
          </span>



          {/* CONTENT: CHARS */}
          {tab === 'chars' && (
            <div>
              <table id="players-online">
                <thead>
                  <tr>
                    <th id="column-name" className={`${sortByChars === 'name' ? 'sort' : ''}${sortAscChars ? ' asc' : ''}`} onClick={() => setSortChars('name')}>{t('online_name')}</th>
                    <th id="column-race" className={`${sortByChars === 'race' ? 'sort' : ''}${sortAscChars ? ' asc' : ''}`} onClick={() => setSortChars('race')}>{t('online_race')}</th>
                    <th id="column-class" className={`${sortByChars === 'class' ? 'sort' : ''}${sortAscChars ? ' asc' : ''}`} onClick={() => setSortChars('class')}>{t('online_class')}</th>
                    <th id="column-level" className={`${sortByChars === 'level' ? 'sort' : ''}${sortAscChars ? ' asc' : ''}`} onClick={() => setSortChars('level')}>{t('online_level')}</th>
                    <th id="column-zone" className={`${sortByChars === 'zone' ? 'sort' : ''}${sortAscChars ? ' asc' : ''}`} onClick={() => setSortChars('zone')}>{t('online_zone')}</th>
                  </tr>
                </thead>
                <tbody>
                  {charsPage.map(x => (
                    <tr key={x.name}>
                      <td>
                        <Icon data={{type: 'faction', value: x.faction}} className="icon" />
                        <Link to={`/armory/character/${x.id}`}>
                          {x.name}
                        </Link>
                      </td>
                      <td>{x.race}</td>
                      <td>
                        <Icon data={{type: 'class', value: x.class}} className="icon" />
                        {x.class}
                      </td>
                      <td>{x.level}</td>
                      <td>{x.zone}</td>
                    </tr>
                  ))}
                  {chars.length === 0 && (
                    <tr>
                      <td colSpan={5} style={{textAlign: 'center', height: '6em'}}>{t('nocharsfound')}</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div id="online-page-controls" className={numCharResults < pageSize ? 'hidden' : undefined}>
                <div id="online-page-control-buttons">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="online-page-control" type="button" disabled={currentPageChars === 1}
                    onClick={() => setPageChars(currentPageChars - 1)}>
                    <IoCaretBack />
                  </button>
                  <span>{currentPageChars}/{Math.ceil(numCharResults / pageSize)}</span>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="online-page-control" type="button" disabled={currentPageChars === chars.length}
                    onClick={() => setPageChars(currentPageChars + 1)}>
                    <IoCaretForward />
                  </button>
                </div>
                <span id="online-page-size" style={{textAlign: 'center'}}>Per page: {pageSize}</span>
              </div>
            </div>
          )}





          {/* CONTENT: ITEMS */}
          {tab === 'items' && (
            <div>
              <table id="players-online" className="item-table">
                <thead>
                  <tr>
                    <th id="column-name" className={`${sortByItems === 'name' ? 'sort' : ''}${sortAscItems ? ' asc' : ''}`} onClick={() => setSortItems('name')}>Name</th>
                    <th id="column-itemlevel" className={`${sortByItems === 'itemlevel' ? 'sort' : ''}${sortAscItems ? ' asc' : ''}`} onClick={() => setSortItems('itemlevel')}>Itemlevel</th>
                  </tr>
                </thead>
                <tbody>
                  {itemsPage.map(x => (
                    <tr key={x.id}>
                      <td>
                        <ZULL_ITEM.ItemIcon item={x} small position={ZULL_COMP.TooltipPosition.CENTERRIGHT}
                          noTooltip={isMobile} />
                        &nbsp;&nbsp;&nbsp;
                        <Link to={`/armory/item/${x.id}`}>
                          {x.name}
                        </Link>
                      </td>
                      <td>{x.itemlevel}</td>
                    </tr>
                  ))}
                  {items.length === 0 && (
                    <tr>
                      <td colSpan={5} style={{textAlign: 'center', height: '6em'}}>{t('noitemsfound')}</td>
                    </tr>
                  )}
                </tbody>
              </table>

              <div id="online-page-controls" className={numItemResults < pageSize ? 'hidden' : undefined}>
                <div id="online-page-control-buttons">
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="online-page-control" type="button" disabled={currentPageItems === 1}
                    onClick={() => setPageItems(currentPageItems - 1)}>
                    <IoCaretBack />
                  </button>
                  <span>{currentPageItems}/{Math.ceil(numItemResults / pageSize)}</span>
                  {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                  <button className="online-page-control" type="button" disabled={currentPageItems === items.length}
                    onClick={() => setPageItems(currentPageItems + 1)}>
                    <IoCaretForward />
                  </button>
                </div>
                <span id="online-page-size" style={{textAlign: 'center'}}>Per page: {pageSize}</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </BasePageLayout >
  );
};

export default PageArmory;
