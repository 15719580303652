import {IoLogoInstagram, IoLogoTiktok, IoLogoYoutube} from 'react-icons/io5';
import './SocialLinks.css';
import {CSSProperties} from 'react';

interface ISocialLinksProps {
  id?: string,
  className?: string,
  style?: CSSProperties
}
const SocialLinks = (props: ISocialLinksProps) => {
  const links = [
    {name: 'TikTok', link: 'https://www.tiktok.com/@wowzull', icon: <IoLogoTiktok />},
    {name: 'Instagram', link: 'https://www.instagram.com/wowzull/', icon: <IoLogoInstagram />},
    {name: 'YouTube', link: 'https://www.youtube.com/@wowzull', icon: <IoLogoYoutube />}
  ];

  return (
    <div id={props.id} className={`social-links-wrapper ${props.className ? ` ${props.className}` : ''}`} style={{...props.style, display: 'flex'}}>
      <div className="flex-pad" />
      {links.map(x => (
        <a href={x.link}
          target="_blank"
          rel="noreferrer noopener"
          title={x.name}
          key={x.name}>
          {x.icon}
        </a>
      ))}
      <div className="flex-pad" />
    </div>
  );
};

export default SocialLinks;
