import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoCaretBack, IoCaretForward} from 'react-icons/io5';
import {ZULL_API} from 'zull-common-js';
import BasePageLayout from './BasePageLayout';
import LoadingIcon from '../components-elements/LoadingIcon';
import './PageOnline.css';
import Icon from '../components-elements/Icon';

type SortBy = 'name' | 'race' | 'class' | 'level' | 'zone';
interface IOnlineEntry {
  id: number,
  name: string,
  race: string,
  /** 0 = Horde, 1 = Alliance */
  faction: 0 | 1,
  class: string,
  level: number,
  zone: string
}

const PageOnline = () => {
  const pageSize = 20;

  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [online, setOnline] = useState<IOnlineEntry[][]>([]);
  const [onlinePage, setOnlinePage] = useState<IOnlineEntry[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [onlineCountTotal, setOnlineCountTotal] = useState(0);
  const [onlineCountHorde, setOnlineCountHorde] = useState(0);
  const [onlineCountAlliance, setOnlineCountAlliance] = useState(0);
  const [sortBy, setSortBy] = useState<SortBy>('name');
  const [sortAsc, setSortAsc] = useState(true);

  const sort = (arr: IOnlineEntry[], by: SortBy, asc: boolean) => arr.sort((a, b) => {
    if (asc) return a[by] < b[by] ? -1 : 1;
    return a[by] > b[by] ? -1 : 1;
  });

  const setPage = (page: number, chunks?: IOnlineEntry[][]) => {
    if (page < 1 || (chunks ? page > chunks.length : page > online.length)) return;
    setCurrentPage(page);
    setOnlinePage(chunks ? chunks[page - 1] : online[page - 1]);  // page is 1-based, arrays 0-based
  };

  const chunkify = (arr: IOnlineEntry[]) => {
    const chunks: IOnlineEntry[][] = [];
    for (let i = 0; i < arr.length; i += pageSize) {
      chunks.push(arr.slice(i, i + pageSize));
    }
    return chunks;
  };

  const update = () => {
    setIsLoading(true);
    ZULL_API.GET({endpoint: 'public/getonline'}).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        return;
      }
      const current = sort(JSON.parse(res.body ?? '') as IOnlineEntry[], sortBy, sortAsc);
      const chunks = chunkify(current);

      setOnline(chunks);
      setPage(1, chunks);
      setOnlineCountTotal(current.length);
      setOnlineCountHorde(current.reduce((n, x) => n + (x.faction === 0 ? 1 : 0), 0));
      setOnlineCountAlliance(current.reduce((n, x) => n + (x.faction === 1 ? 1 : 0), 0));
      setIsLoading(false);
    });
  };

  const setSort = (key: SortBy) => {
    let asc = sortAsc;
    let by = sortBy;
    if (sortBy === key) {
      setSortAsc(!asc);
      asc = !asc;
    } else {
      setSortAsc(true);
      asc = true;
      setSortBy(key);
      by = key;
    }
    const current = chunkify(sort(online.flat(), by, asc));
    setOnline(current);
    setPage(currentPage, current);
  };

  useEffect(() => {
    update();
  }, []);

  if (isLoading) return (
    <BasePageLayout title={t('account')}>
      <LoadingIcon />
    </BasePageLayout>
  );

  return (
    <BasePageLayout title={t('online_title')}>
      <div id="page-online-wrapper">
        <div id="online-counts">
          <h3>{t('online_total')} <span className="online-count">{onlineCountTotal}</span></h3>
          <h4>{t('online_horde')} <span className="online-count">{onlineCountHorde}</span></h4>
          <h4>{t('online_alliance')} <span className="online-count">{onlineCountAlliance}</span></h4>
        </div>

        <div id="page-online-content">
          <div id="online-table-wrapper">
            <table id="players-online">
              <thead>
                <tr>
                  <th id="column-name" className={`${sortBy === 'name' ? 'sort' : ''}${sortAsc ? ' asc' : ''}`} onClick={() => setSort('name')}>{t('online_name')}</th>
                  <th id="column-race" className={`${sortBy === 'race' ? 'sort' : ''}${sortAsc ? ' asc' : ''}`} onClick={() => setSort('race')}>{t('online_race')}</th>
                  <th id="column-class" className={`${sortBy === 'class' ? 'sort' : ''}${sortAsc ? ' asc' : ''}`} onClick={() => setSort('class')}>{t('online_class')}</th>
                  <th id="column-level" className={`${sortBy === 'level' ? 'sort' : ''}${sortAsc ? ' asc' : ''}`} onClick={() => setSort('level')}>{t('online_level')}</th>
                  <th id="column-zone" className={`${sortBy === 'zone' ? 'sort' : ''}${sortAsc ? ' asc' : ''}`} onClick={() => setSort('zone')}>{t('online_zone')}</th>
                </tr>
              </thead>
              <tbody>
                {onlinePage.map(x => (
                  <tr key={x.name}>
                    <td>
                      <Icon data={{type: 'faction', value: x.faction}} className="icon" />
                      <Link to={`/armory/character/${x.id}`}>
                        {x.name}
                      </Link>
                    </td>
                    <td>{x.race}</td>
                    <td>
                      <Icon data={{type: 'class', value: x.class}} className="icon" />
                      {x.class}
                    </td>
                    <td>{x.level}</td>
                    <td>{x.zone}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        <div id="online-page-controls" className={onlineCountTotal < pageSize ? 'hidden' : undefined}>
          <div id="online-page-control-buttons">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className="online-page-control" type="button" disabled={currentPage === 1}
              onClick={() => setPage(currentPage - 1)}>
              <IoCaretBack />
            </button>
            <span>{currentPage}</span>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button className="online-page-control" type="button" disabled={currentPage === online.length}
              onClick={() => setPage(currentPage + 1)}>
              <IoCaretForward />
            </button>
          </div>
          <span id="online-page-size">{t('online_items_per_page')} {pageSize}</span>
        </div>
      </div>
    </BasePageLayout>
  );
};

export default PageOnline;
