import {ZULL_API} from 'zull-common-js';

/**
 * Converts a string returned from SQL to a JS Date object
 * @param sqldate date string as 'yyyy-mm-dd hh-mm-ss'
 * @returns null on error
 */
export const SQLDateToJSDate = (sqldate: string): Date | null => {
  try {
    const res = new Date();
    const parts = sqldate.split(' ');
    const dateparts = parts[0].split('-');
    const timeparts = parts[1].split(':');
    res.setUTCFullYear(parseInt(dateparts[0]));
    res.setUTCMonth(parseInt(dateparts[1]) - 1);
    res.setUTCDate(parseInt(dateparts[2]));
    res.setUTCHours(parseInt(timeparts[0]));
    res.setUTCMinutes(parseInt(timeparts[1]));
    res.setUTCSeconds(parseInt(timeparts[2]));
    return res;
  } catch (error) {
    console.error(error);
    return null;
  }
};

interface IAccountData {
  userid: number,
  username: string,
  nickname: string,
  email: string,
  dp: number,
  vp: number,
  joindate: string,
  lastLogin: string,
  totaltime: number
}
export const getAccountData = async (args: {user: string, pass: string}) => {
  const res = await ZULL_API.GET({
    endpoint: `account/${args.user}/getdata`,
    authUser: args.user,
    authPass: args.pass
  });
  if (!res.ok || !res.body) {
    console.error(res);
    return;
  }
  const parsed = JSON.parse(res.body) as IAccountData;
  return {
    userid: parsed.userid,
    username: parsed.username,
    nickname: parsed.nickname,
    email: parsed.email,
    dp: parsed.dp,
    vp: parsed.vp,
    joindate: SQLDateToJSDate(parsed.joindate),
    lastLogin: SQLDateToJSDate(parsed.lastLogin),
    totaltime: parsed.totaltime
  };
};
