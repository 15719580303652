import {useContext, useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from '../helpers/globalContext';
import LoadingIcon from '../components-elements/LoadingIcon';
import BasePageLayout from './BasePageLayout';
import WarningBanner from '../components-elements/WarningBanner';

const PageDonateSuccess = () => {
  let hasCaptured = false;
  const {token} = useParams();
  const {t} = useTranslation();
  const {globalState, setGlobalState} = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState<false | number>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newDP, setNewDP] = useState(0);

  const capture = () => {
    ZULL_API.GET({endpoint: `account/${token}/capturedonate`}).then(res => {
      if (!res.ok || !res.body) {
        console.error(res);
        setIsError(res.status);
        setErrorMessage(res.body ?? '');
        setIsLoading(false);
      } else {
        const response = JSON.parse(res.body) as {addeddp: number, newdp: number};
        if (globalState.isLoggedIn) setGlobalState(state => ({...state, dp: response.newdp}));
        setNewDP(response.addeddp);
        setIsError(false);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    if (localStorage.getItem('login') === null) return;
    if (!globalState.isLoginLoading && globalState.isLoggedIn && !hasCaptured) {
      hasCaptured = true;
      capture();
    }
  }, [globalState.isLoginLoading]);
  useEffect(() => {
    if (localStorage.getItem('login') !== null) return;
    if (!hasCaptured) {
      hasCaptured = true;
      capture();
    }
  }, []);

  if (isLoading) return (
    <BasePageLayout title="">
      <LoadingIcon />
    </BasePageLayout>
  );

  if (isError !== false) return (
    <BasePageLayout title="">
      <WarningBanner type="error">
        {isError === 404 || !errorMessage ? t('donate_error_token') : /* t('donate_error') */ errorMessage}
      </WarningBanner>
    </BasePageLayout>
  );

  return (
    <BasePageLayout title="">
      <div id="donate-success">
        <h2 style={{
          textAlign: 'center',
          fontFamily: 'var(--font-accent)',
          fontSize: '2.5em',
          textShadow: 'var(--text-glow)',
          textTransform: 'uppercase'
        }}>{t('donate_thanks')}</h2>

        <div style={{
          padding: '1em',
          margin: '1em',
          marginTop: '4em',
          background: 'var(--background-glass-accent-low)',
          borderRadius: 'var(--border-radius)',
          boxShadow: 'var(--box-glow-large)'
        }}>
          <span style={{
            display: 'block',
            margin: '1em 0',
            textAlign: 'center',
            fontFamily: 'var(--font-accent)',
            fontSize: '3em',
            color: '#fffa28',
            textShadow: '0px 4px 0 #cc8900, 0px 0px 12px #fffa28'
          }}>+ {newDP} DP</span>
        </div>
        <br />
        <br />
        <WarningBanner type="info">
          {t('donate_relog')}
        </WarningBanner>
      </div>
    </BasePageLayout>
  );
};

export default PageDonateSuccess;
