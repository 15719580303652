import {useTranslation} from 'react-i18next';
import BasePageLayout from './BasePageLayout';

const Page404 = () => {
  const {t} = useTranslation();
  return (
    <BasePageLayout title="">
      <div style={{textAlign: 'center', padding: '1em'}}>
        <h1 style={{margin: 0, marginBottom: '1em'}}>404</h1>
        {t('404')}
      </div>
    </BasePageLayout>
  );
};

export default Page404;
