import {useNavigate} from 'react-router-dom';
import {useContext} from 'react';
import {ZULL_API} from 'zull-common-js';
import {GlobalContext} from './globalContext';
import useStorage, {IStorageLogin} from './useStorage';
import {getAccountData} from './preparedAPICalls';

const useLoginManager = () => {
  const navigate = useNavigate();
  const {globalState, setGlobalState} = useContext(GlobalContext);
  const {loginStorage} = useStorage();

  enum ELoginError {
    NONE,
    NOUSER,
    NOPASS,
    LOGININVALID,
    VERIFICATIONPENDING,
    GENERIC
  }

  const login = async (args: IStorageLogin): Promise<ELoginError> => {
    setGlobalState({...globalState, isLoginLoading: true});
    if (!args || !args.user || !args.pass) {
      if (!args.user) return ELoginError.NOUSER;
      if (!args.pass) return ELoginError.NOPASS;
      return ELoginError.GENERIC;
    }

    const authres = await ZULL_API.GET({
      endpoint: 'auth',
      authUser: args.user,
      authPass: args.pass
    });
    if (!authres.ok) {
      setGlobalState({...globalState, isLoginLoading: false});
      if (authres.status === 500) return ELoginError.GENERIC;
      if (authres.body?.includes('verification pending')) {
        return ELoginError.VERIFICATIONPENDING;
      } return ELoginError.LOGININVALID;
    }

    const datares = await getAccountData({
      user: args.user,
      pass: args.pass
    });
    if (!datares) {
      console.error(datares);
      setGlobalState({...globalState, isLoginLoading: false});
      return ELoginError.GENERIC;
    }
    setGlobalState({
      ...globalState,
      isLoggedIn: true,
      isLoginLoading: false,
      username: args.user,
      password: args.pass,
      userid: datares.userid,
      nickname: datares.nickname,
      email: datares.email,
      dp: datares.dp,
      vp: datares.vp,
      joindate: datares.joindate,
      lastLogin: datares.lastLogin,
      totaltime: datares.totaltime
    });
    return ELoginError.NONE;
  };

  const logout = () => {
    loginStorage.clear();
    document.cookie = 'log=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict';
    setGlobalState({
      ...globalState,
      isLoggedIn: false,
      username: null,
      password: null
    });
    navigate('/');
  };

  return {login, ELoginError, logout};
};

export default useLoginManager;
