import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoHome, IoCheckmark, IoLogoPaypal, IoLogoDiscord, IoHelp, IoShield} from 'react-icons/io5';
import {GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';
import GlobalContextProvider from './helpers/globalContext';
import WarningBanner from './components-elements/WarningBanner';
import BasePageLayout from './pages/BasePageLayout';
import PageHome from './pages/PageHome';
import GlobalLayout from './GlobalLayout';
import MDPage from './pages/MDPage';
import PageRegister from './pages/PageRegister';
import PageResetPW from './pages/PageResetPW';
import PageAccount from './pages/PageAccount';
import Page404 from './pages/Page404';
import PageHelp from './pages/PageHelp';
import PageOnline from './pages/PageOnline';
import PageVote from './pages/PageVote';
import PageDonate from './pages/PageDonate';
import PageDonateSuccess from './pages/PageDonateSuccess';
import PageArmoryCharacter from './pages/PageArmoryCharacter';
import PageArmoryItem from './pages/PageArmoryItem';
import PageArmory from './pages/PageArmory';

const SuccessRedirectScreen = (props: {message: string}) => {
  const {t} = useTranslation();
  return (
    <BasePageLayout title={t('success')}>
      <WarningBanner type="info">
        {props.message}
      </WarningBanner>
    </BasePageLayout>
  );
};

const App = () => {
  const {t, i18n} = useTranslation();

  const routes = [
    {linkTarget: '/', content: t('nav_home'), icon: <IoHome />},
    {linkTarget: '/armory', content: t('nav_armory'), icon: <IoShield />},
    {linkTarget: '/donate', content: t('nav_donate'), icon: <IoLogoPaypal />},
    {linkTarget: '/vote', content: t('nav_vote'), icon: <IoCheckmark />},
    {linkTarget: '/help', content: t('nav_help'), icon: <IoHelp />},

    // IF THIS LINK IS EVER CHANGED, ALSO CHANGE IN PageHelp and help MDs (use search, there may be mutiple links per file) !!!
    {linkTarget: 'https://discord.gg/EKs7jzdXGQ', content: t('nav_discord'), icon: <IoLogoDiscord />, external: true},
  ];

  return (
    <GlobalContextProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<GlobalLayout routes={routes} />}>
            <Route index element={<PageHome />} />

            <Route path="guide" element={<MDPage file="guide" title={t('nav_guide')} />} />
            <Route path="rules" element={<MDPage file="rules" title={t('nav_rules')} />} />
            <Route path="register" element={
              <GoogleReCaptchaProvider reCaptchaKey="6LcU21ApAAAAAH9bpGMp07Bzre1COj0MFl3o0nAN"
                language={i18n.language.substring(0, 2)}
                container={{
                  element: 'recaptcha-container',
                  parameters: {
                    badge: 'inline',
                    theme: 'dark',
                    tabindex: 5
                  }
                }}>
                <PageRegister />
              </GoogleReCaptchaProvider>} />
            <Route path="help" element={<PageHelp />} />
            <Route path="help/technical" element={<MDPage file="help_technical" title={t('help_technical')} />} />
            <Route path="help/account" element={<MDPage file="help_account" title={t('help_account')} />} />
            <Route path="help/game" element={<MDPage file="help_game" title={t('help_game')} />} />
            <Route path="account" element={<PageAccount />} />
            <Route path="online" element={<PageOnline />} />
            <Route path="vote" element={<PageVote />} />
            <Route path="donate" element={<PageDonate />} />
            <Route path="donatesuccess/:token" element={<PageDonateSuccess />} />

            <Route path="armory" element={<PageArmory />} />
            <Route path="armory/character/:id" element={<PageArmoryCharacter />} />
            <Route path="armory/item/:id" element={<PageArmoryItem />} />

            <Route path="imprint" element={<MDPage file="imprint" title={t('nav_imprint')} />} />
            <Route path="tos" element={<MDPage file="tos" title={t('nav_tos')} />} />
            <Route path="privacy" element={<MDPage file="privacy" title={t('nav_privacy')} />} />
            <Route path="refundpolicy" element={<MDPage file="refundpolicy" title={t('nav_refundpolicy')} />} />

            {/* Redirects from API mails (eg. verification emails) */}
            <Route path="resetpw/*" element={<PageResetPW />} />
            <Route path="emailchangeold" element={<SuccessRedirectScreen message={t('account_changeemail_verifiedold')} />} />
            <Route path="emailchangenew" element={<SuccessRedirectScreen message={t('account_changeemail_verifiednew')} />} />

            <Route path="*" element={<Page404 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </GlobalContextProvider>
  );
};

export default App;
