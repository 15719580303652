import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {GlobalContext} from '../helpers/globalContext';
import BasePageLayout from './BasePageLayout';
import './MDPage.css';

interface IMDPageProps {
  file: string,
  title: string
}
const MDPage = (props: IMDPageProps) => {
  const {i18n} = useTranslation();
  const {globalState} = useContext(GlobalContext);
  const [contentMD, setContentMD] = useState<string>('');

  const getContent = (file: string) => {
    fetch(i18n.language.substring(0, 2) === 'de' ? `${globalState.baseURL}pages/${file}-de.md` : `${globalState.baseURL}pages/${file}-en.md`).then(res => {
      if (!res.ok) console.error(`${res.url} Response: ${res.status}`);
      return res.text();
    }).then(res => setContentMD(res.replaceAll('{{baseurl}}', globalState.baseURL)));
  };

  const applyContentTransforms = () => {
    // find all elements that are a collapsible section to attach onClick handlers
    const collapsible = Array.from(document.getElementsByClassName('collapsible-section'));
    collapsible.forEach(section => {
      section.getElementsByTagName('h3')[0].onclick = () => {
        section.classList.toggle('show');
        const content = section.getElementsByClassName('collapsible-content')[0];
        if (content.getAttribute('data-isopen') === 'true') {
          content.setAttribute('data-isopen', 'false');
          content.setAttribute('style', 'height: 0px');
        } else {
          content.setAttribute('data-isopen', 'true');
          content.setAttribute('style', `height: ${content.scrollHeight}px`);
        }
      };
    });
  };

  useEffect(() => getContent(props.file), [props.file]);
  useEffect(() => applyContentTransforms, [contentMD]);

  return (
    <BasePageLayout title={props.title}>
      <div id="markdown-content">
        <Markdown rehypePlugins={[rehypeRaw]}>
          {contentMD}
        </Markdown>
      </div>
    </BasePageLayout>
  );
};

export default MDPage;
