import {Link} from 'react-router-dom';
import StartButtonNormal from '../assets/big_button.png';
import StartButtonHover from '../assets/big_button-hover.png';
import './BigButton.css';

interface IBigButtonProps {
  id?: string,
  className?: string,
  linkTarget: string,
  content: string
}
const BigButton = (props: IBigButtonProps) => (
  <Link to={props.linkTarget} id={props.id} className={`big-button${props.className ? ` ${props.className}` : ''}`}>
    <div className="big-button-content image" style={{backgroundImage: `url(${StartButtonNormal})`}} />
    <div className="big-button-content hover" style={{backgroundImage: `url(${StartButtonHover})`}} />
    <span className="big-button-text">
      {props.content}
    </span>
  </Link>
);

export default BigButton;
