/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import {CSSProperties, JSXElementConstructor, ReactElement} from 'react';
import './Card.css';

type RecursiveArray = (ReactElement | RecursiveArray)[];

interface ICardProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>[] | ReactElement<any, string | JSXElementConstructor<any>>,
  className?: string
}
export const Card = (props: ICardProps) => (
  <div className={`card${props.className ? ` ${props.className}` : ''}`}>
    {props.children}
  </div>
);

const CardWrapperSegment = (props: {arr: RecursiveArray, id: string}) => (
  <div className="card-wrapper-segment">
    {
      props.arr.map((x, index) => (
        Array.isArray(x) ?
          <CardWrapperSegment id={`${props.id}-${index}`} key={`${props.id}-${index}`} arr={x} /> :
          (x as ReactElement)
      ))
    }
  </div>
);

interface ICardWrapperProps {
  id: string,
  children: ReactElement<any, string | JSXElementConstructor<any>>[],
  className?: string,
  style?: CSSProperties
}
export const CardWrapper = (props: ICardWrapperProps) => {
  // split array into halves until there are only two / three entries per array
  const chunkify = (arr: any[]) => arr.reduce((result, value, index, array) => {
    if (index % 2 === 0) result.push(array.slice(index, index + 2));
    return result;
  }, []);
  let splitArray: RecursiveArray = props.children;
  while (splitArray.length > 2) splitArray = chunkify(splitArray);

  return (
    <div id={props.id} className={`card-wrapper${props.className ? ` ${props.className}` : ''}`} style={props.style}>
      {splitArray.map((x, index) => (
        <CardWrapperSegment id={`${props.id}-${index}`} key={`${props.id}-${index}`} arr={x as RecursiveArray} />
      ))}
    </div>
  );
};
