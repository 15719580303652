/* eslint-disable jsx-a11y/no-static-element-interactions */
import {useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {IoPeople} from 'react-icons/io5';
import Slider from 'react-slick';
import {ZULL_API} from 'zull-common-js';
import Modal from '../components-elements/Modal';
import BigButton from '../components-elements/BigButton';
import {Card, CardWrapper} from '../components-elements/Card';
import Picture from '../components-elements/Picture';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './PageHome.css';

const PageHome = () => {
  const {t} = useTranslation();

  const [modalFeatureRacesOpen, setModalFeatureRacesOpen] = useState(false);
  const [modalFeatureRatesOpen, setModalFeatureRatesOpen] = useState(false);
  const [modalFeatureScalingOpen, setModalFeatureScalingOpen] = useState(false);
  const [modalFeatureCrossfactionOpen, setModalFeatureCrossfactionOpen] = useState(false);
  const [modalFeatureTransmogOpen, setModalFeatureTransmogOpen] = useState(false);
  const [modalFeatureParagonOpen, setModalFeatureParagonOpen] = useState(false);

  const [countAccounts, setCountAccounts] = useState(0);
  const [countChars, setCountChars] = useState(0);

  useEffect(() => {
    ZULL_API.GET({endpoint: 'public/acccharcount'}).then(res => {
      if (res.ok && res.body) {
        const ret = JSON.parse(res.body);
        setCountAccounts(ret.accounts);
        setCountChars(ret.chars);
      }
    });
  }, []);

  return (
    <div id="main-page-content">
      <div id="main-page-section-start" className="main-page-section">
        <Slider dots={false} infinite speed={1500}
          slidesToShow={1} slidesToScroll={1} className="carousel"
          arrows={false} autoplaySpeed={4000} autoplay
          centerMode fade centerPadding="0">
          <div className="carousel-slide">
            <h3>{t('carousel_feature_rates_heading')}</h3>
            <p>{t('carousel_feature_rates_text')}</p>
          </div>
          <div className="carousel-slide">
            <h3>{t('carousel_feature_races_heading')}</h3>
            <p>{t('carousel_feature_races_text')}</p>
          </div>
          <div className="carousel-slide">
            <h3>{t('carousel_feature_scaling_heading')}</h3>
            <p>{t('carousel_feature_scaling_text')}</p>
          </div>
          <div className="carousel-slide">
            <h3>{t('carousel_feature_crossfaction_heading')}</h3>
            <p>{t('carousel_feature_crossfaction_text')}</p>
          </div>
          <div className="carousel-slide">
            <h3>{t('carousel_feature_others_heading')}</h3>
            <p>{t('carousel_feature_others_text')}</p>
          </div>
        </Slider>

        <Picture path="logo-large" id="logo-large" alt="" />
        <BigButton id="big-button-start" linkTarget="/guide" content={t('start_button')} />
      </div>

      <div id="main-page-section-features" className="main-page-section background">
        <div className="main-page-section-content">
          <h2>{t('features')}</h2>
          <h3>{t('features_sub')}</h3>
          <CardWrapper id="main-page-feature-card-wrapper">
            <Card className="feature-card">
              <Picture path="features/feature_races" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#9bbbe5'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureRacesOpen(true)}>
                <Picture path="features/feature_races_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_races_heading')}</h4>
                <div className="content">{t('features_races_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#9bbbe5'}} />
            </Card>

            <Card className="feature-card">
              <Picture path="features/feature_rates" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#f3b405'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureRatesOpen(true)}
                role="button" tabIndex={0}>
                <Picture path="features/feature_rates_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_rates_heading')}</h4>
                <div className="content">{t('features_rates_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#f3b405'}} />
            </Card>

            <Card className="feature-card">
              <Picture path="features/feature_scaling" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#5ccae3'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureScalingOpen(true)}>
                <Picture path="features/feature_scaling_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_scaling_heading')}</h4>
                <div className="content">{t('features_scaling_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#5ccae3'}} />
            </Card>

            <Card className="feature-card">
              <Picture path="features/feature_crossfaction" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#c3c85c'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureCrossfactionOpen(true)}>
                <Picture path="features/feature_crossfaction_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_crossfaction_heading')}</h4>
                <div className="content">{t('features_crossfaction_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#c3c85c'}} />
            </Card>

            <Card className="feature-card">
              <Picture path="features/feature_transmog" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#6eb8e7'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureTransmogOpen(true)}>
                <Picture path="features/feature_transmog_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_transmog_heading')}</h4>
                <div className="content">{t('features_transmog_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#f3b405'}} />
            </Card>

            <Card className="feature-card">
              <Picture path="features/feature_paragon" alt="" className="feature-img"
                lazy />
              <div className="img-glow large-glow" style={{background: '#63c8f4'}} />
              <div className="card-content" data-readmore={t('read_more')} onClick={() => setModalFeatureParagonOpen(true)} >
                <Picture path="features/feature_paragon_bg" alt="" className="feature-img-bg" />
                <div className="img-overlay" />
                <h4>{t('features_paragon_heading')}</h4>
                <div className="content">{t('features_paragon_teaser')}</div>
              </div>
              <div className="img-glow" style={{background: '#63c8f4'}} />
            </Card>
          </CardWrapper>
        </div>
      </div>


      <div id="main-page-section-cta" className="main-page-section">
        <div className="main-page-section-content">
          <div className="h-wrapper">
            <h2>{t('call_to_action_title')}</h2>
            <h3>{t('call_to_action_sub')}</h3>
          </div>

          <BigButton id="big-button-end" linkTarget="/guide" content={t('start_button')} />

          <div id="user-stats">
            <span id="user-stats-title">{t('call_to_action_counttitle')}</span>
            <span className="user-stats-entry">
              {t('call_to_action_accounts')} <span className="num">{countAccounts}</span>
            </span>
            <span className="user-stats-entry">
              {t('call_to_action_chars')} <span className="num">{countChars}</span>
            </span>

            <Link to="/online" id="user-stats-online-link" className="button icon-button">
              <IoPeople />
              <span>{t('call_to_action_online')}</span>
            </Link>
          </div>
        </div>
      </div>



      <Modal fillContent isOpen={modalFeatureRacesOpen} onClose={() => setModalFeatureRacesOpen(false)}>
        <div className="feature-modal-content">
          <div className="modal-title">
            <h4>{t('features_races_heading')}</h4>
            <hr />
          </div>
          <div className="feature-modal-section">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_voidelf" alt="" />
            </div>
            <span>{t('feature_races_voidelf')}</span>
            <hr />
          </div>
          <div className="feature-modal-section">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_goblin" alt="" />
            </div>
            <span>{t('feature_races_goblin')}</span>
            <hr />
          </div>
          <div className="feature-modal-section">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_nightborne" alt="" />
            </div>
            <span>{t('feature_races_nightborne')}</span>
            <hr />
          </div>
          <div className="feature-modal-section">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_worgen" alt="" />
            </div>
            <span>{t('feature_races_worgen')}</span>
          </div>
        </div>
      </Modal>

      <Modal fillContent isOpen={modalFeatureRatesOpen} onClose={() => setModalFeatureRatesOpen(false)}>
        <div className="feature-modal-content nosections">
          <div className="modal-title">
            <h4>{t('features_rates_heading')}</h4>
          </div>
          <div className="content">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_rates" alt="" />
            </div>
            <div className="content-text">
              <div className="flex-pad" />
              <span>{t('feature_rates_1')}</span>
              <hr />
              <span>{t('feature_rates_2')}</span>
              <hr />
              <span>{t('feature_rates_3')}</span>
              <div className="flex-pad" />
            </div>
          </div>
        </div>
      </Modal>

      <Modal fillContent isOpen={modalFeatureScalingOpen} onClose={() => setModalFeatureScalingOpen(false)}>
        <div className="feature-modal-content nosections">
          <div className="modal-title">
            <h4>{t('features_scaling_heading')}</h4>
          </div>
          <div className="content">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_scaling" alt="" />
            </div>
            <div className="content-text">
              <div className="flex-pad" />
              <span>{t('feature_scaling_1')}</span>
              <hr />
              <span>{t('feature_scaling_2')}</span>
              <hr />
              <span>{t('feature_scaling_3')}</span>
              <div className="flex-pad" />
            </div>
          </div>
        </div>
      </Modal>

      <Modal fillContent isOpen={modalFeatureCrossfactionOpen} onClose={() => setModalFeatureCrossfactionOpen(false)}>
        <div className="feature-modal-content nosections">
          <div className="modal-title">
            <h4>{t('features_crossfaction_heading')}</h4>
          </div>
          <div className="content">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_crossfaction" alt="" />
            </div>
            <div className="content-text">
              <div className="flex-pad" />
              <span>{t('feature_crossfaction_1')}</span>
              <hr />
              <span>{t('feature_crossfaction_2')}</span>
              <div className="flex-pad" />
            </div>
          </div>
        </div>
      </Modal>

      <Modal fillContent isOpen={modalFeatureTransmogOpen} onClose={() => setModalFeatureTransmogOpen(false)}>
        <div className="feature-modal-content nosections">
          <div className="modal-title">
            <h4>{t('features_transmog_heading')}</h4>
          </div>
          <div className="content">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_transmog" alt="" />
            </div>
            <div className="content-text">
              <div className="flex-pad" />
              <span>{t('feature_transmog_1')}</span>
              <span>{t('feature_transmog_2')}</span>
              <hr />
              <span>{t('feature_transmog_3')}</span>
              <div className="flex-pad" />
            </div>
          </div>
        </div>
      </Modal>

      <Modal fillContent isOpen={modalFeatureParagonOpen} onClose={() => setModalFeatureParagonOpen(false)}>
        <div className="feature-modal-content nosections">
          <div className="modal-title">
            <h4>{t('features_paragon_heading')}</h4>
          </div>
          <div className="content">
            <div className="clip-wrapper">
              <Picture lazy path="features/feature_showcase_paragon" alt="" />
            </div>
            <div className="content-text">
              <div className="flex-pad" />
              <span>{t('feature_paragon_1')}</span>
              <hr />
              <span>{t('feature_paragon_2')}</span>
              <div className="flex-pad" />
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default PageHome;
