import {Suspense} from 'react';
import {createRoot} from 'react-dom/client';
import './locale';
import './global-styles/theme.css';
import './global-styles/fonts.css';
import './global-styles/globals.css';
import './global-styles/buttons.css';
import App from './App';
import LoadingIcon from './components-elements/LoadingIcon';
import Picture from './components-elements/Picture';

const Preload = () => (
  <div style={{width: '100vw', height: '100vh'}}>
    <Picture path="logo-large" alt="" style={{
      position: 'absolute',
      top: '30%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(0px 24px 18px rgba(0, 0, 0, 1))'
    }} />
    <div style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      filter: 'drop-shadow(var(--text-glow))',
      fontFamily: 'var(--font-accent)',
      textTransform: 'uppercase'
    }}>
      <LoadingIcon />
      <h1>Loading...</h1>
    </div>
  </div >
);

const root = createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Suspense fallback={<Preload />}>
    <App />
  </Suspense>
);
