import {JSXElementConstructor, ReactElement, useEffect, useRef} from 'react';
import './BasePageLayout.css';

interface IBasePageLayoutProps {
  children: ReactElement<any, string | JSXElementConstructor<any>>,
  title: string,
  noPadding?: boolean
}
const BasePageLayout = (props: IBasePageLayoutProps) => {
  const h1container = useRef<HTMLDivElement>(null);
  const h1 = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (h1container.current && h1.current) {
      const containerWidth = parseFloat(getComputedStyle(h1container.current).getPropertyValue('width'));
      let fontSize = parseFloat(getComputedStyle(h1.current).getPropertyValue('font-size'));
      while (h1.current.offsetWidth > containerWidth) {
        fontSize -= 1;
        h1.current.style.fontSize = `${fontSize}px`;
      }
    }
  }, []);

  return (
    <div id="base-page-layout">
      <div id="h1-container" ref={h1container}>
        <h1 ref={h1}>{props.title}</h1>
      </div>
      <div id="base-page-content-glass">
        <div id="base-page-content" style={props.noPadding ? {padding: 0} : undefined}>
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default BasePageLayout;
